import React, { useState, useEffect, useRef } from "react";
import Header from "../components/headers/Header";
import { useParams } from "react-router-dom";
import LoadingIcon from "../assets/svg/loading.svg";
import feature from "../assets/fake-data/landingpage-two/feature";
import faq from "../assets/fake-data/landingpage-one/faq";
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import ContentSection from "../features/common/ContentSection";
import SwiperLandingPageOne from "../features/common/SwiperLandingPageOne";
import { Analytics } from "@vercel/analytics/react"
import { Line } from 'react-chartjs-2';
import { Link as ScrollLink } from "react-scroll";
import Chart from 'chart.js/auto'; // Import Chart.j
import {
  FaCalendar,
  FaGasPump,
  FaPrint,
  FaSave,
  FaTrash,
  FaLocationArrow,
  FaLeaf,
  FaUser,
  FaBell,
  FaShare,
  FaCar,
} from "react-icons/fa"; // Import the icons
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  Card,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  CardBody,
  CardHeader,
} from "@material-tailwind/react";
import { createClient } from "@supabase/supabase-js";
import FooterOne from "../components/footers/FooterOne";
import "../assets/css/print.css";
import ReactQuill from "react-quill"; // Import the editor
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { Input } from "@material-tailwind/react";
import DOMPurify from 'dompurify';
import Features from "../features/landingpage-two/Features";



 const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_API
);  


  const HomePage = () => {
  const [dataBlock] = useState({
    title: "Controllo Km Auto Gratis",
    subTitle:
      "Verifica e Controllo della Revisione Auto - Ottieni tutte le informazioni necessarie per un acquisto sicuro.",
    since: "N°1 in Italia",
  });

  const { plate } = useParams();
  const resultSectionRef = useRef(null);
  const [isSectionVisible, setIsSectionVisible] = useState(false); // New state to control the visibility
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);
  const [progress, setProgress] = useState(0);
  const [plateData, setPlateData] = useState(null);
  const [carDetails, setCarDetails] = useState(null);
  const [tableRows, setTableRows] = useState();

  const [checkKM, setCheckKM] = useState(null); // null initially, true if no manipulation, false if manipulation detected
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareLink, setShareLink] = useState("");

  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [reportData, setReportData] = useState({
    datRvs: "",
    numKmiPcsRvs: "",
  });
  const [manipulatedReports, setManipulatedReports] = useState([]);
  const [chartData, setChartData] = useState(null);

  
  const [checkedPlateData, setCheckedPlateData] = useState([]);
  const [checkForManipulationFunction, setCheckForManipulationFunction] =
    useState(null);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [reportComment, setReportComment] = useState("");
  const [milageDifference, setMilageDifference] = useState(null);

  useEffect(() => {
    // Attempt to retrieve the userId from localStorage, or generate a new one
    let storedUserId = localStorage.getItem("userId");
    if (!storedUserId) {
      storedUserId = `user_${new Date().getTime()}`;
      localStorage.setItem("userId", storedUserId);
    }
    setUserId(storedUserId);
  }, []);
  useEffect(() => {
    if (checkedPlateData && checkedPlateData.length > 0) {
      console.log(checkedPlateData);
      const chartData = {
        labels: checkedPlateData.map(data => {
          const [year, month, day] = data.datRvs.split('-'); // Correctly split the date
          return `${day}/${month}/${year}`; // Format the date as DD/MM/YYYY
          console.log(checkedPlateData);
        }), 
        datasets: [
          {
            label: 'Kilometers',
            data: checkedPlateData.map(data => data.numKmiPcsRvs),
            fill: false,
            borderColor: 'rgb(75, 192, 192)', 
            tension: 0.1 
          }
        ]
      };
  
      setChartData(chartData);
      console.log("Chart data updated:", chartData); // Console log the chart data
    } else {
      console.log("checkedPlateData is empty or undefined:", checkedPlateData);
    }
  }, [checkedPlateData]); 

  const saveSearch = (newSearch) => {
    let searches = JSON.parse(localStorage.getItem("recentSearches")) || [];
    searches = [newSearch, ...searches];
    localStorage.setItem("recentSearches", JSON.stringify(searches));
    setRecentSearches(searches); // Update state to trigger re-render
  };

  const TABLE_HEAD = ["Date", "Esito", "Mileage"];

  const handleOpen = () => setOpen(!open);

  // Function to check for odometer manipulation
  const checkForOdometerManipulation = (input) => {
    // Log the input data to understand what's being processed
    const data = Array.isArray(input) ? { informations: input } : input;

    console.log("Input data for manipulation check:", data);

    // The rest of your function remains the same...
    if (!data || !data.informations || !Array.isArray(data.informations)) {
      console.log("Data is invalid or not in the expected format.");
      return []; // Return an empty array if data is invalid
    }

    // Assuming plateData.informations contains the array of { datRvs, numKmiPcsRvs }
    // Log the pre-sorted data for comparison
    console.log("Pre-sorted data:", data.informations);

    const sortedData = data.informations.sort(
      (a, b) => new Date(a.datRvs) - new Date(b.datRvs)
    );

    // Log the sorted data to verify the sort operation
    console.log("Sorted data:", sortedData);

    for (let i = 1; i < sortedData.length; i++) {
      if (sortedData[i].numKmiPcsRvs < sortedData[i - 1].numKmiPcsRvs) {
        // Found a case where mileage decreases, flag this entry
        sortedData[i].isManipulated = true;
        // Log each instance of manipulation detected
        console.log(`Manipulation detected at index ${i}:`, sortedData[i]);
      } else {
        // No manipulation detected
        sortedData[i].isManipulated = false;
      }
    }
    const isManipulated = sortedData.some((entry) => entry.isManipulated); // true if any entry is manipulated

    setCheckKM(!isManipulated); // Set to true if no manipulation, false otherwise

    // Log the final data to see the outcome of the manipulation check
    console.log("Final data after manipulation check:", sortedData);

    return sortedData;
  };

  const addNextRevisionDate = (data) => {
    // Check if the last item is already a future date before adding a new one
    const lastItemDate = new Date(data[data.length - 1].datRvs);
    const now = new Date();

    if (lastItemDate > now) {
      console.log(
        "Future revision date already present, not adding a new one."
      );
      return data; // Return the original data if the last date is in the future
    }

    const nextRevisionDate = new Date(lastItemDate); // Make a copy of lastItemDate
    nextRevisionDate.setFullYear(nextRevisionDate.getFullYear() + 2); // Increase the year by 2
    const formattedDate = nextRevisionDate.toISOString().split("T")[0];
    const newItem = {
      ...data[data.length - 1],
      datRvs: formattedDate,
      // Reset or adjust additional properties as needed
    };

    data.push(newItem);

    console.log("Data after adding next revision date:", data);

    return data;
  };

  const getNextRevisionDate = (data) => {
    if (data.length === 0) {
      return null;
    }

    const lastItemDate = new Date(data[data.length - 1].datRvs);
    const nextRevisionDate = new Date(
      lastItemDate.setFullYear(lastItemDate.getFullYear())
    );
    return nextRevisionDate.toISOString().split("T")[0]; // Return the formatted next revision date
  };

  const calculateDaysUntil = (date) => {
    if (!date) {
      return 0; // or "N/A" or any default value that makes sense in your context
    }
    const now = new Date();
    const revisionDate = new Date(date);
    const timeDiff = revisionDate - now;
    return Math.floor(timeDiff / (1000 * 3600 * 24)); // Return the days until or since the next revision date
  };

  const nextRevisionFormattedDate = getNextRevisionDate(checkedPlateData);
  const daysUntilNextRevision = calculateDaysUntil(nextRevisionFormattedDate);

  const handleSearch = async (event) => {
    event.preventDefault();
    const plateValue = event.target.elements.targa.value.toUpperCase(); // Ensuring uppercase to match the regex pattern
    setIsLoading(true);
    setProgress(50); // Set initial progress

    // Adjusting the regex to be case-insensitive and to include optional spaces
    const platePattern = /^[A-Z]{2}\s?\d{3}\s?[A-Z]{2}$/i;

    if (platePattern.test(plateValue)) {
      setIsValid(true);

      performSearchOriginal(plateValue); // Correctly using plateValue here
    } else {
      setIsValid(false);
      setErrorMessage(
        "Formato della targa non valido. Si prega di utilizzare il formato: AB 123 CD o AB123CD"
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      if (plate) {
        try {
          // First, log the plate we're about to search for.
          console.log("Attempting to load data for plate:", plate);

          const { data, error } = await supabase
            .from("revision")
            .select("*")
            .eq("plate", plate);

          if (error) {
            throw error;
          }

          // Check how many rows were returned for further debug.
          console.log(`Found ${data.length} row(s) for plate: ${plate}`);

          // Ensure there's exactly one record returned; adjust as needed.
          if (data.length === 1) {
            const record = data[0]; // Assuming the first (and only) record is what we want.
            const lastRevisionDate = new Date(record.last_revision_date);
            const currentDate = new Date();

            lastRevisionDate.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);
            console.log("last day Rev", lastRevisionDate);
            console.log("Current day rev", currentDate);

            if (lastRevisionDate < currentDate) {
              console.log(
                "Last revision date is in the past, performing a new search for plate:",
                plate
              );
              performSearch(plate);
              setIsSectionVisible(!isSectionVisible);
            } else {
              console.log("Loading data from database for plate:", plate);
              const jsonResponse = await fetch(record.rev_json);
              const storedData = await jsonResponse.json();

              setCarDetails(storedData.carDetails);
              setCheckedPlateData(storedData.checkedPlateData);
              console.log(storedData.checkedPlateData);
              setIsSectionVisible(!isSectionVisible);
            }
          } else if (data.length === 0) {
            console.log(
              "No data found in database, performing a new search for plate:",
              plate
            );
            // Perform new search
          } else {
            console.log("Multiple records found, handling not implemented.");
            // Handle multiple records case, if applicable.
          }
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    };

    loadData();
  }, [plate]);

  // Mock function to simulate fetching data from an API
  const fetchMockRevisionData = () => {
    return new Promise((resolve) => {
      const mockData = {
        codice: "200",
        esito: "OK",
        informations: [
          {
            datRvs: "2021-09-02 07:30:15.0",
            flgAnlRvs: "N",
            flgEsiRvsVei: "P",
            numKmiPcsRvs: 23000,
            plate: "DX111PP",
          },
          {
            datRvs: "2019-09-17 07:30:15.0",
            flgAnlRvs: "N",
            flgEsiRvsVei: "P",
            numKmiPcsRvs: 66000,
            plate: "DX111PP",
          },
        ],
      };
      // Simulate a network request with setTimeout
      setTimeout(() => resolve(mockData), 500);
    });
  };

  const performSearch = (plateValue) => {
    setIsLoading(true);
    setProgress(50);

    // Here you replace the fetch call with fetchMockRevisionData
    fetchMockRevisionData()
      .then((data) => {
        if (data.codice === "200" && Array.isArray(data.informations)) {
          console.log(data);
          setPlateData(data);
          let transformedData = data.informations.map((item) => ({
            datRvs: item.datRvs,
            numKmiPcsRvs: item.numKmiPcsRvs,
          }));
          console.log("Before any transformations:", transformedData);

          // Update TABLE_ROWS with initial transformed data
          setTableRows(transformedData);

          // Check for odometer manipulation
          const result = checkForOdometerManipulation({
            informations: transformedData,
          });
          console.log("After checkForOdometerManipulation:", result);

          // Add next revision date
          transformedData = addNextRevisionDate(transformedData);
          console.log("After addNextRevisionDate:", transformedData);

          setCheckedPlateData(transformedData);
          console.log("DATA COMPLETED CHECK", checkedPlateData);

          saveSearch(plateValue);
          return fetch(
            `https://api.fastcoperture.it:5001/getCarDetails/${plateValue}`
          );
        }
      })
      .then((response) => response.json())
      .then((detailsData) => {
        setCarDetails(detailsData); // Store the car details data in state
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(error.message || "Failed to fetch data.");
      })
      .finally(() => {
        setIsLoading(false);
        setProgress(100);
        setIsSectionVisible(true);
      });
  };

  const performSearchOriginal = (plateValue) => {
    setIsLoading(true);
    setProgress(50); // Set initial progress

    const platePattern = /^[A-Z]{2}\s?\d{3}\s?[A-Z]{2}$/;

    if (platePattern.test(plateValue)) {
      setIsValid(true);

      fetch(`https://api.fastcoperture.it:5001/fetchPlate/${plateValue}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.codice === "200" && Array.isArray(data.informations)) {
            console.log(data);
            setPlateData(data);
            let transformedData = data.informations.map((item) => ({
              datRvs: item.datRvs, // Use the correct property from your fetched data
              numKmiPcsRvs: item.numKmiPcsRvs, // Use the correct property from your fetched data
            }));
            console.log("Before any transformations:", transformedData);

            // Updating the state of TABLE_ROWS with initial transformed data
            setTableRows(transformedData);

            // First transformation: Check for odometer manipulation
            const result = checkForOdometerManipulation({
              informations: transformedData,
            });
            console.log("After checkForOdometerManipulation:", result);

            // Second transformation: Add next revision date
            transformedData = addNextRevisionDate(transformedData);
            console.log("After addNextRevisionDate:", transformedData);

            setCheckedPlateData(transformedData);
            console.log("DATA COMPLETED CHECK", checkedPlateData);

            saveSearch(plateValue); // Assuming saveSearch is defined to handle local storage

            return fetch(
              `https://api.fastcoperture.it:5001/getCarDetails/${plateValue}`
            );
          } else {
            setErrorMessage(
              "Per il veicolo selezionato non risultano revisioni effettuate dopo il 1 giugno 2018"
            );
            throw new Error(
              "No revisions found for the selected vehicle after June 1, 2018."
            );
          }
        })
        .then((response) => response.json())
        .then((detailsData) => {
          setCarDetails(detailsData); // Store the car details data in state
        })
        .catch((error) => {
          console.error("Error:", error);
          setErrorMessage(error.message || "Failed to fetch data.");
        })
        .finally(() => {
          setIsLoading(false);
          setProgress(100);
          // Move setIsSectionVisible here if appropriate and ensure data for display is ready
          if (carDetails || plateData) {
            setIsSectionVisible(true);
          }
        });
    } else {
      setIsValid(false);
      setErrorMessage(
        "Formato della targa non valido. Si prega di utilizzare il formato: AB 000 AB"
      );
      setIsLoading(false);
    }
  };

  // Function to fetch revision data
  function fetchRevisionData(plateValue) {
    return fetch(`https://api.fastcoperture.it:5001/fetchPlate/${plateValue}`)
      .then((response) => response.json())
      .then((data) => {
        if (
          data.codice === "200" &&
          data.informations &&
          data.informations.length > 0
        ) {
          console.log("Revision data found:", data);
          processPlateData(data); // Implement this based on your needs
          return true;
        } else {
          console.log(
            "No revision data found or specific condition met:",
            data
          );
          // Proceed to check matriculation year when no revision data is found
          return fetchMatriculationYear(plateValue).then(
            (hasMatriculationPassedFourYears) => {
              if (!hasMatriculationPassedFourYears) {
                // Handle cases where matriculation hasn't passed 4 years yet.
                console.log(
                  "Matriculation hasn't passed 4 years yet. Handling accordingly."
                );
              }
              return false; // Since no revision data was found, indicate this for further processing.
            }
          );
        }
      });
  }

  const performSearchNew = (plateValue) => {
    setIsLoading(true);
    setProgress(50);

    const platePattern = /^[A-Z]{2}\s?\d{3}\s?[A-Z]{2}$/;
    if (!platePattern.test(plateValue)) {
      setIsValid(false);
      setErrorMessage(
        "Formato della targa non valido. Si prega di utilizzare il formato: AB 000 AB"
      );
      setIsLoading(false);
      return;
    }

    setIsValid(true);

    fetchRevisionData(plateValue)
      .then((hasData) => {
        if (hasData) {
          return fetchAndDisplayCarDetails(plateValue);
        } else {
          return fetchMatriculationYear(plateValue);
        }
      })
      .then(() => {
        setIsSectionVisible(true);
      })
      .catch((error) => {
        console.error("Error during the fetch process:", error);
        setErrorMessage(error.message || "Failed to fetch data.");
      })
      .finally(() => {
        setIsLoading(false);
        setProgress(100);
      });
  };

  function calculateNextRevisionDate(data) {
    const [matriculationMonth, matriculationYear] = data.matriculation_year
      .split("/")
      .map(Number);
    const fourYearsFromMatriculation = new Date(
      matriculationYear + 4,
      matriculationMonth - 1
    );
    return fourYearsFromMatriculation.toISOString().split("T")[0];
  }

  function fetchMatriculationYear(plateValue) {
    console.log(`Fetching matriculation year for plate: ${plateValue}`);
    return fetch(`http://127.0.0.1:5555/getCarMatriculationYear/${plateValue}`)
      .then((response) => {
        console.log(
          `Response status for matriculation year fetch: ${response.status}`
        );
        return response.json();
      })
      .then((data) => {
        console.log("Received data:", data);
        if (data && data.matriculation_year) {
          console.log(`Matriculation year found: ${data.matriculation_year}`);
          const [matriculationMonth, matriculationYear] =
            data.matriculation_year.split("/").map(Number);
          console.log(
            `Matriculation date: ${matriculationMonth}/${matriculationYear}`
          );

          const matriculationDate = new Date(
            matriculationYear,
            matriculationMonth - 1
          );
          const currentDate = new Date();
          console.log(`Current date: ${currentDate.toISOString()}`);

          const fourYearsFromMatriculation = new Date(
            matriculationDate.getFullYear() + 4,
            matriculationDate.getMonth(),
            matriculationDate.getDate()
          );
          console.log(
            `Four years from matriculation date: ${fourYearsFromMatriculation.toISOString()}`
          );
          updateTableWithMatriculationYear(fourYearsFromMatriculation);
          fetchAndDisplayCarDetails(plateValue);
          saveSearch(plateValue);
          if (carDetails || plateData) {
            setIsSectionVisible(true);
          } // Ensuring visibility is set true to show updated table

          if (currentDate >= fourYearsFromMatriculation) {
            console.log(
              "4 years have passed since matriculation. Proceeding to fetch and display car details."
            );
            // return fetchAndDisplayCarDetails(plateValue).then(() => true); // Indicate that 4 years have passed
          }
        } else {
          console.log(
            "Matriculation year data not found. Unable to determine next steps."
          );
          return false;
        }
      })
      .catch((error) => {
        console.error(`Error fetching matriculation year: ${error}`);
        return false;
      });
  }

  function updateTableWithMatriculationYear(matriculationDate) {
    if (!(matriculationDate instanceof Date)) {
      console.error("Expected matriculationDate to be a Date object.");
      return;
    }

    console.log(`Updating table with matriculation date: ${matriculationDate}`);

    // Calculate next revision date based on 4 years from the matriculation date
    const nextRevisionDate = new Date(
      matriculationDate.getFullYear(),
      matriculationDate.getMonth(),
      matriculationDate.getDate()
    )
      .toISOString()
      .split("T")[0];
    console.log(`Calculated next revision date: ${nextRevisionDate}`);

    // Assuming you store your table data in `checkedPlateData` state
    const newData = [
      ...checkedPlateData,
      {
        datRvs: nextRevisionDate,
        numKmiPcsRvs: "---", // Placeholder for mileage data
        isManipulated: false, // Provide a default value
      },
    ];

    // Logging new data to be added for verification
    console.log(`New data to be added to table:`, newData[newData.length - 1]);
    setTableRows(newData);
    // Update state with the new data array
    setCheckedPlateData(newData);

    // Verifying state update (this log may not immediately reflect the new state due to async nature of setState)
    console.log(`Initiated update of table data with new entry.`);

    // This will not log the updated state immediately due to async nature of setState
    console.log("checkedPlateData (post-update attempt):", checkedPlateData);
  }

  useEffect(() => {
    console.log("checkedPlateData updated:", checkedPlateData);
  }, [checkedPlateData]);

  useEffect(() => {
    console.log(carDetails);
  }, [carDetails]);

  // Function to fetch car details
  function fetchAndDisplayCarDetails(plateValue) {
    return fetch(`http://127.0.0.1:5555/getCarDetails/${plateValue}`)
      .then((response) => response.json())
      .then((detailsData) => {
        setCarDetails(detailsData); // Assuming this sets the car details state
        console.log("Car details data:", detailsData);
      });
  }

  // Implement this function based on how you want to process the initial plate data
  function processPlateData(data) {
    // Example processing
    console.log("Processing plate data:", data);
    // Set relevant state here if needed
  }

  useEffect(() => {
    if (plateData || carDetails) {
      // Depending on which one triggers the display
      setIsSectionVisible(true);
      // Optional: Scroll into view
      resultSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [plateData, carDetails]); // Add other dependencies as necessary

  useEffect(() => {
    const loadRecentSearches = () => {
      try {
        const storedSearches = localStorage.getItem("recentSearches");
        const recentSearches = storedSearches ? JSON.parse(storedSearches) : [];
        setRecentSearches(recentSearches);
      } catch (error) {
        console.error("Error loading recent searches:", error);
      }
    };

    loadRecentSearches();
  }, []);

  const handleShare = async () => {
    let userId = localStorage.getItem("userId");
    if (!userId) {
      userId = `user_${new Date().getTime()}`; // Generate a new user ID if not existing
      localStorage.setItem("userId", userId);
      console.log(`New user ID generated: ${userId}`);
    } else {
      console.log(`Existing user ID found: ${userId}`);
    }

    // Assuming carDetails and plateData are available in your component's state
    console.log("Preparing data to share...", { carDetails, plateData });

    const dataToShare = {
      carDetails, // carDetails obtained from somewhere in your component
      checkedPlateData, // plateData obtained from somewhere in your component
    };

    console.log("Checked Plated ", checkedPlateData);

    const blob = new Blob([JSON.stringify(dataToShare)], {
      type: "application/json",
    });
    const fileName = `json/${encodeURIComponent(new Date().toISOString())}_${
      carDetails.plate
    }.json`;

    console.log(`Uploading JSON to Supabase Storage as: ${fileName}`);

    // Upload the JSON blob to Supabase Storage
    const { data: uploadData, error: uploadError } = await supabase.storage
      .from("revision")
      .upload(fileName, blob);

    if (uploadError) {
      console.error("Upload error:", uploadError);
      return;
    } else {
      console.log("Upload successful:", uploadData);
    }

    // Create a signed URL for the uploaded file
    const { data: signedData, error: signedError } = await supabase.storage
      .from("revision")
      .createSignedUrl(fileName, 31536000); // Valid for one year

    if (signedError) {
      console.error("Signed URL error:", signedError);
      return;
    } else {
      console.log("Signed URL created:", signedData.signedUrl);
    }

    // Insert the revision record into your database with the signed URL
    const revisionRecord = {
      plate: carDetails.plate,
      rev_json: signedData.signedUrl,
      last_revision_date: nextRevisionFormattedDate,
      last_user_id: userId,
    };

    console.log("Inserting revision record into database...", revisionRecord);

    const { error } = await supabase.from("revision").insert([revisionRecord]);
    if (error) {
      console.error("Error sharing revision:", error.message);
    } else {
      console.log("Revision shared successfully. Data:", revisionRecord);
      const constructedShareLink = `http://localhost:3000/targa/${carDetails.plate}`;
      setShareLink(constructedShareLink); // Save the link in state

      // Open the share modal
      setShowShareModal(true);
    }
  };

  const ShareModal = ({ isOpen, onClose, shareLink }) => {
    // Function to copy the shareLink to clipboard
    const copyLink = () => {
      navigator.clipboard.writeText(shareLink).then(
        () => {
          // Notification or feedback that the link has been copied
          alert("Link copied to clipboard!");
        },
        (err) => {
          console.error("Could not copy text: ", err);
        }
      );
    };
    return (
      <Dialog open={isOpen} handler={onClose}>
        <DialogBody>
          <Typography variant="h5" className="font-bold">
            Share Modal
          </Typography>

          <Typography className="my-4">Share this link via</Typography>

          {/* Icons container */}
          <div className="flex justify-around my-4">
            <div class="border hover:bg-[#1877f2] w-12 h-12 fill-[#1877f2] hover:fill-white border-blue-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-blue-500/50 cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
              </svg>
            </div>

            <div class="border hover:bg-[#1d9bf0] w-12 h-12 fill-[#1d9bf0] hover:fill-white border-blue-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-sky-500/50 cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"></path>
              </svg>
            </div>

            <div class="border hover:bg-[#bc2a8d] w-12 h-12 fill-[#bc2a8d] hover:fill-white border-pink-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-pink-500/50 cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                <circle cx="16.806" cy="7.207" r="1.078"></circle>
                <path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path>
              </svg>
            </div>

            <div class="border hover:bg-[#25D366] w-12 h-12 fill-[#25D366] hover:fill-white border-green-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-green-500/50 cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263"
                ></path>
              </svg>
            </div>

            <div class="border hover:bg-[#229ED9] w-12 h-12 fill-[#229ED9] hover:fill-white border-sky-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-sky-500/50 cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"></path>
              </svg>
            </div>
          </div>

          <Typography className="text-sm">Or copy link</Typography>

          <div className="flex items-center justify-between px-4 py-2 mt-4 border-2 border-gray-200">
            <input className="w-full" type="text" readOnly value={shareLink} />
            <button
              onClick={copyLink}
              className="ml-4 text-indigo-600 hover:text-indigo-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.929l-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465l2.122-2.122a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z"
                />
              </svg>
            </button>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button variant="outlined" color="gray" onClick={onClose}>
            Close
          </Button>
        </DialogFooter>
      </Dialog>
    );
  };

  const handlePrint = () => {
    // Ensure the section is visible
    const section = document.getElementById("printableSection");
    section.style.opacity = "1";
    section.style.visibility = "visible";

    window.print();

    // Optional: Revert visibility after printing if needed
    window.onafterprint = () => {
      section.style.opacity = "0";
      section.style.visibility = "invisible";
    };
  };

  const toggleSectionVisibility = () => {
    setIsSectionVisible(!isSectionVisible);
  };

  const handleOpenCloseShareModal = () => setShowShareModal(!showShareModal);

  const handleOpenReportDialog = (datRvs, numKmiPcsRvs) => {
    setReportData({ datRvs, numKmiPcsRvs });
    setIsReportDialogOpen(true);
  };

  const handleCloseReportDialog = () => {
    setIsReportDialogOpen(false);
  };

  // La funzione assume che `sortedData` sia un array di revisioni ordinate cronologicamente, come quello restituito da `checkForOdometerManipulation`
  const calculateMilageDifference = (sortedData) => {
    // Cerca la prima occorrenza di manipolazione
    const manipulatedIndex = sortedData.findIndex(
      (entry) => entry.isManipulated
    );

    if (manipulatedIndex === -1 || manipulatedIndex === 0) {
      // Se non c'è manipolazione, o la manipolazione è alla prima revisione, non c'è una "differenza" calcolabile
      return null;
    }

    // Calcola la differenza di chilometraggio tra la revisione manipolata e la precedente
    const milageDifference =
      sortedData[manipulatedIndex].numKmiPcsRvs -
      sortedData[manipulatedIndex - 1].numKmiPcsRvs;

    // Restituisce la differenza di chilometraggio come valore assoluto, poiché la manipolazione potrebbe portare a una differenza negativa
    return Math.abs(milageDifference);
  };

  useEffect(() => {
    if (checkedPlateData.length > 0) {
      const sortedData = checkForOdometerManipulation({
        informations: checkedPlateData,
      });
      const difference = calculateMilageDifference(sortedData);
      setMilageDifference(difference);
      console.log("Differenza di chilometraggio: ", difference);
    }
  }, [checkedPlateData]); // Dipendenze: esegui l'effetto ogni volta che `checkedPlateData` cambia

  const handleSubmitReport = async () => {
    const sortedData = checkForOdometerManipulation({
      informations: checkedPlateData,
    });
    const milageDifference = calculateMilageDifference(sortedData);
    console.log("Differenza di chilometraggio: ", milageDifference);

    const payload = {
      user_id: userId, // Assicurati che questo valore sia disponibile nello stato o dal contesto di autenticazione
      plate: carDetails?.plate, // Usa la targa attualmente visualizzata o selezionata dall'utente
      comment: reportComment, // Il commento inserito nell'editor ReactQuill
      milage_difference: milageDifference, // Implementa questa funzione in base ai tuoi dati
      rev_json: JSON.stringify({ carDetails, checkedPlateData }), // Inserisci qui i dettagli aggiuntivi se necessari
    };

    try {
      const { error } = await supabase
        .from("manipulatedplate")
        .insert([payload]);
      if (error) throw error;
      alert("Segnalazione inviata con successo!");
      handleCloseReportDialog(); // Chiude il dialog dopo l'invio
    } catch (error) {
      console.error(
        "Errore nel salvataggio della segnalazione:",
        error.message
      );
    }
  };

useEffect(() => {
    const fetchManipulatedReports = async () => {
      try {
        let { data: reports, error } = await supabase
          .from("manipulatedplate")
          .select("*");
          
        if (error) throw error;
  
        if (!reports || reports.length === 0) {
          console.warn("No reports found or reports array is empty.");
        } else {
          console.log("Fetched reports:", reports);
        }
        
        setManipulatedReports(reports);
      } catch (error) {
        console.error("Error fetching reports: ", error.message);
      }
    };

    fetchManipulatedReports();
  }, []);
  

  const SafeHTMLContent = ({ html }) => {
    // Sanificare l'HTML
    const cleanHTML = DOMPurify.sanitize(html);
  
    // Utilizzare dangerouslySetInnerHTML per inserire l'HTML sanificato
    return <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />;
  };

   let calendar = {
    title: 'Sample Event',
    description: 'This is the sample event provided as an example only',
    location: 'Portland, OR',
    startTime: '2016-09-16T20:15:00-04:00',
    endTime: '2016-09-16T21:45:00-04:00'
};






  return (
    <>
      <Dialog open={isReportDialogOpen} handler={handleCloseReportDialog}>
        <DialogBody>
          <Typography variant="h5" className="mt-4 font-bold">
            Segnalazione Chilometraggio Manipolato
          </Typography>
          <Typography variant="body1" className="mt-4">
            Oops, sembra che il chilometraggio della tua auto sia stato
            manipolato. Ti consigliamo di verificare l'ultima revisione con il
            chilometraggio dichiarato e, se hai acquistato l'auto di recente, di
            contattare chi te l'ha venduta.
          </Typography>
          <div className="flex items-center space-x-4">
            <img
              src={`https://api.dicebear.com/8.x/adventurer/svg?seed=${userId}`}
              alt="avatar"
              className="w-16 h-16 rounded-full"
            />
            <Input
              size="lg"
              placeholder="Il tuo nome (opzionale)"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              value={userName} // Assuming you have a state to hold this value
              onChange={(e) => setUserName(e.target.value)} // And a method to update it
            />
          </div>

          <ReactQuill
            theme="snow"
            value={reportComment} // Assuming you have a state to hold the editor content
            onChange={setReportComment} // And a method to update it
            className="mt-4"
          />
        </DialogBody>
        <DialogFooter>
          <Button
            variant="outlined"
            color="gray"
            onClick={handleCloseReportDialog}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            color="green"
            onClick={() => handleSubmitReport()}
          >
            Invia Segnalazione
          </Button>
        </DialogFooter>
      </Dialog>
      ;<Header></Header>
      <section className="md:pt-[160px] md:pb-[170px] pt-[100px] pb-[120px] relative bg-cover bg-center bg-themedark" id="home">
        <div
          className="absolute inset-0 w-full h-full bg-center bg-no-repeat bg-cover blur"
          style={{
            backgroundImage:
              'url("https://themeenergy.com/themes/html/transfers/images/uploads/keyvisual.jpg")',
            opacity: 0.25,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          }}
        ></div>
        <div className="container relative flex items-center justify-center sm:mt-9 ">
          <div className="grid grid-cols-1 lg:grid-cols-1 items-center gap-[20px] ">
            <div className="lg:col-span-1 ">
              <p className="py-2.5 px-5 bg-white/[0.03] italic inline-block rounded-lg text-custom mb-4 md:mb-0">
                {dataBlock.since}
         
              </p>
              <h1 className="mt-4 md:text-[56px] text-[34px] leading-[1.2] text-white">
                {dataBlock.title}
              </h1>
              <p className="mt-6 text-lg text-white/40">{dataBlock.subTitle}</p>
            </div>
            <div className="mt-1 lg:w-full sm:w-full lg:col-span-1">
              <form onSubmit={handleSearch} className="space-y-2">
                <div className="flex items-center w-full">
                  <input
                    type="text"
                    name="targa"
                    className="flex-grow px-5 text-center border-none rounded-lg h-15 form-input"
                    placeholder="Inserisci la Targa"
                    required
                  />
                  <button
                    className="flex items-center justify-center px-5 ml-2 btn btn-sm btn-custom navbar-btn btn-rounded"
                    type="submit"
                  >
                    {isLoading ? (
                      <>
                        <img
                          src={LoadingIcon}
                          alt="Loading"
                          className="inline-block h-3 mr-2 w-7"
                        />{" "}
                        {/* Adjust w-3 and h-3 to fit your design */}
                        Carico...
                      </>
                    ) : (
                      "Verifica"
                    )}
                  </button>
                </div>
                {!isValid && (
                  <p className="mt-2 text-sm text-red-500">{errorMessage}</p>
                )}
                <div className="block w-full h-px my-10 bg-white/8 md:my-50"></div>
                <div className="space-y-4 sm:space-y-0">
                  {/* Check if recentSearches array is not empty before rendering the section */}
                  {recentSearches.length > 0 && (
                    <div>
                      <div className="inline-block">
                        <span className="text-white capitalize">Ricerche:</span>
                        {recentSearches.slice(0, 3).map((plate, index) => (
                          <div
                            key={index}
                            className={`inline-block px-2 py-1 mx-3 my-2 text-white border rounded ${
                              index === 0 && recentSearches.length > 5
                                ? "fade-left"
                                : ""
                            }`}
                          >
                            <span className="font-semibold">
                              {plate.substring(0, 5)}
                            </span>
                            {plate.substring(5)}
                          </div>
                        ))}
                        {recentSearches.length > 5 && (
                          <div className="inline-block px-2 py-1 mx-3 my-2 text-white border rounded">
                            <span className="font-semibold">
                              {recentSearches[5].substring(0, 5)}
                            </span>
                            {recentSearches[5].substring(5)}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-[30px] mt-20">
                    {feature.map((x) => {
                      return (
                        <div
                          key={x.id}
                          className="bg-white py-[26px] px-5 rounded-md relative -top-[130px] border border-[#e7e7e7]/50"
                        >
                          <div className="flex items-center">
                            <div className="bg-custom shrink-0 h-[50px] w-[50px] leading-[50px] rounded-lg text-center mr-4">
                              <img
                                src={x.image}
                                className="h-[26px] mx-auto inline-block"
                                alt="..."
                              />
                            </div>
                            <div className="grow">
                              <h5 className="text-xl font-bold">
                                {x.description}
                              </h5>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div> */}
                </div>
              </form>
            </div>
            <div className="mt-10 ">
              <div className="grid lg:grid-cols-3 gap-[30px] sm:grid-cols-2">
              {feature.map((x, index) => (
  <div
    key={index}
    className="relative py-[10px] px-3 rounded-md shadow-sm"
    style={{ background: 'rgba(255, 255, 255, 0.1)', backdropFilter: 'blur(2px)' }}
  >
    <div className="flex items-center">
      <div className="bg-custom shrink-0 h-[20px] w-[20px] leading-[20px] rounded-lg text-center text-white mr-4 flex items-center justify-center"> {/* Aggiunta classe text-white per rendere il testo bianco */}
        {x.icon} {/* Qui utilizziamo il componente icona */}
      </div>
      <div className="grow">
        <h5 className="text-xl font-normal text-white">{x.description}</h5>
      </div>
    </div>
  </div>
))}



              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        id="printableSection"
        style={{ display: isSectionVisible ? "block" : "none" }} // This line controls the visibility
      >
        <div
          ref={resultSectionRef}
          id="printableSection"
          className={`${
            plateData ? "opacity-100 visible" : "opacity-0 invisible"
          } transition-opacity duration-500`}
        >
          <section className="py-20 bg-light">
            <div className="containerPlate ">
              <div className="grid grid-cols-1 gap-4 mt-5 md:grid-cols-3">
                {carDetails &&
                  carDetails.versions &&
                  carDetails.versions.length > 0 && (
                    <>
                      <div className="flex items-center p-3.5 space-x-4 rounded-full">
                        <div class="grid place-items-center bg-neutral-100">
                          <div class="rounded-lg bg-white shadow-lg">
                            <div class="flex w-full rounded-lg border-1 border-green bg-white-500 shadow">
                              <label class="flex flex-col justify-between bg-blue-700 rounded-l-lg p-4 text-1xl font-bold text-white text-center">
                                <img
                                  class="h-4"
                                  src="https://cdn.cdnlogo.com/logos/e/51/eu.svg"
                                />
                                I
                              </label>
                              <label class="p-4 font-mono text-3xl font-bold text-center">
                                {carDetails?.plate}
                              </label>
                              <label class="flex flex-col justify-between bg-blue-700 rounded-r-lg p-4 text-1xl font-bold text-white">
                                {carDetails?.region}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Car Details Column */}
                      <div className="flex items-center p-3.5 space-x-4 rounded-full">
                        <span className="flex items-center justify-center w-10 h-10 text-gray-400 bg-white rounded-full shrink-0">
                          <img
                            src={
                              carDetails.car_logo ||
                              "https://i.postimg.cc/zqPs4t7w/fiat.png"
                            }
                            alt={`${carDetails.versions[0].brand_name} logo`}
                            className="w-10 h-10"
                          />
                        </span>
                        <div className="flex-1">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold"
                          >
                            {carDetails.versions[0].brand_name}{" "}
                            {carDetails.versions[0].model_name}
                          </Typography>
                          <Typography variant="h8" color="blue-gray">
                            {carDetails.versions[0].version_name}
                          </Typography>
                        </div>
                      </div>

                      {/* Next Revision Date Column */}
                      <div className="flex items-center p-3.5 space-x-4 rounded-full">
                        <span className="flex items-center justify-center w-10 h-10 text-gray-400 bg-white rounded-full shrink-0">
                          <FaCalendar />
                        </span>
                        <div className="ml-2 text-sm">
                          <Typography color="blue-gray" className="font-medium">
                            Prossima Revisione
                          </Typography>
                          {daysUntilNextRevision >= 0 ? (
                            <Typography color="gray">
                              {nextRevisionFormattedDate} (
                              {daysUntilNextRevision} giorni rimanenti)
                            </Typography>
                          ) : (
                            <div>
                              <Typography color="red" className="font-medium">
                                Revisione scaduta
                              </Typography>
                              <Typography color="red">
                                Scaduta da {-daysUntilNextRevision} giorni
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
              </div>

              <div className="flex items-center ">
                <Card className="w-full h-full">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="rounded-none"
                  >
                    <div className="gap-8 mb-4 md:flex-row md:items-center">
                      <div>
                        <Typography variant="h5" color="blue-gray">
                          Revisioni
                        </Typography>
                        <Typography color="gray" className="mt-1 font-normal">
                          Lista di tutte le revisioni
                        </Typography>
                      </div>
                      <div className="flex w-full gap-2 shrink-0 md:w-max">
                        <div className="w-full md:w-72"></div>
                      </div>
                    </div>
                  </CardHeader>

                  {plateData && (
                    <Card className="w-full h-full overflow-hidden">
                      <table className="w-full text-left table-auto min-w-max">
                        <thead>
                          <tr>
                            {TABLE_HEAD.map((head) => (
                              <th
                                key={head}
                                className="p-4 border-b border-blue-gray-100 bg-blue-gray-50"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                            <th className="p-4 border-b border-blue-gray-100 bg-blue-gray-50">
                              {" "}
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none opacity-70"
                              >
                                Notificami
                              </Typography>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {checkedPlateData.map(
                            (
                              { datRvs, numKmiPcsRvs, isManipulated },
                              index
                            ) => {
                              const isLastRev =
                                index === checkedPlateData.length - 1; // Correctly identifies the last revision row
                              const classes =
                                "p-4 border-b border-blue-gray-50"; // Use the same class for all to maintain consistency
                              const manipulationClass = isManipulated
                                ? "bg-red-100"
                                : "";

                              return (
                                <tr
                                  key={index}
                                  className={`${manipulationClass}`}
                                >
                                  <td className={classes}>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-medium"
                                    >
                                      {datRvs.match(/\d{4}-\d{2}-\d{2}/)[0]}
                                    </Typography>
                                  </td>
                                  <td
                                    className={`${classes} bg-blue-gray-50/50`}
                                  >
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className={`${
                                        isLastRev
                                          ? "font-bold filter blur-[2px]"
                                          : "font-bold"
                                      } ${
                                        !isLastRev && !isManipulated
                                          ? "text-green-600"
                                          : ""
                                      }`}
                                    >
                                      {isLastRev
                                        ? "-------"
                                        : isManipulated
                                        ? "Km Manipolato"
                                        : "Positivo"}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className={
                                        isLastRev
                                          ? "font-normal filter blur-[2px]"
                                          : "font-normal"
                                      }
                                    >
                                      {isLastRev
                                        ? "-------"
                                        : `${Number(
                                            numKmiPcsRvs
                                          ).toLocaleString("it-IT")} Km`}{" "}
                                      {isManipulated && !isLastRev && (
                                        <span>⚠️</span>
                                      )}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    {isManipulated ? (
                                      <button
                                        className="px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
                                        onClick={() =>
                                          handleOpenReportDialog(
                                            datRvs,
                                            numKmiPcsRvs
                                          )
                                        }
                                      >
                                        Segnala
                                      </button>
                                    ) : isLastRev ? (
                                      <add-to-calendar-button 
                                      name="Scadenza revisone"
                                      description="Oggi scade la revisione della tua auto"
                                      startDate={datRvs}
                                      startTime="10:15"
                                      endTime="17:45"
                                      timeZone="Europe/Berlin"
                                      location="Centro"
                                      options="'Apple','Google','iCal','Outlook.com','Yahoo','Microsoft365','MicrosoftTeams'"
                                      trigger="click"
                                      hideTextLabelButton
                                      size="4"
                                    ></add-to-calendar-button>
                                    
                                    ) : (
                                      <div>
                                        {" "}
                                        {/* Empty div for spacing if neither condition is true */}{" "}
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                      <div className="flex items-center space-x-4 p-3.5 rounded-full">
                        <span className="relative flex items-center justify-center w-10 h-10 text-gray-400 bg-white rounded-full shrink-0">
                          <span className="relative flex w-5 h-5">
                            <span
                              className={`animate-ping absolute inline-flex h-full w-full rounded-full ${
                                checkKM ? "bg-green-400" : "bg-red-400"
                              } opacity-75`}
                            ></span>
                            <span
                              className={`relative inline-flex rounded-full h-5 w-5 ${
                                checkKM ? "bg-green-500" : "bg-red-500"
                              }`}
                            ></span>
                          </span>
                        </span>
                        <div className="flex flex-col flex-1">
                          <Typography variant="small" className="font-medium">
                            Verifica Km
                          </Typography>
                          {checkKM ? (
                            <Typography variant="small" color="gray">
                              Ultima revisione: km ok
                            </Typography>
                          ) : (
                            <Typography variant="small" color="red">
                              Oops, il km nel veicolo è stato scalato di{" "}
                              {milageDifference} Km
                            </Typography>
                          )}
                        </div>
                      </div>
                    </Card>
                  )}
                </Card>
              </div>
              <div className="flex items-center justify-center p-3.5 space-x-4 rounded-full mt-2">
                <div className="inline-flex rounded-md shadow-sm" role="group">
                  <button
                    type="button"
                    onClick={handleShare}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                  >
                    <FaShare></FaShare>
                    Condividi
                  </button>
                  <ShareModal
                    isOpen={showShareModal}
                    onClose={() => setShowShareModal(false)}
                    shareLink={shareLink}
                  />
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                    onClick={handlePrint}
                  >
                    <FaPrint></FaPrint>
                    Stampa
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                  >
                    <FaCar></FaCar>
                    Valuta Veicolo
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      
      <div className="py-20 bg-gray-100">
  <div className="container mx-auto">
    <div className="text-center">
      <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
        <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
        Lista veicoli segnalati
      </h2>
      <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
        Tutti i veicoli segnalati con km manipolato 
      </p>
    </div>
    <div className="grid gap-4 mt-16 md:grid-cols-3">
      {manipulatedReports.map((report, index) => {
        let carDetails, checkedPlateData;
        try {
          ({ carDetails, checkedPlateData } = JSON.parse(report.rev_json));
        } catch (error) {
          console.error("Failed to parse JSON:", error);
          return null; // Skip this iteration if JSON parsing fails
        }

        const lastManipulated = checkedPlateData.find(({ isManipulated }) => isManipulated);

        if (!carDetails || !checkedPlateData) {
          console.error("Missing carDetails or checkedPlateData:", { carDetails, checkedPlateData });
          return null;
        }

        // Prepare chart data
        const chartData = {
          labels: checkedPlateData.map(data => new Date(data.datRvs).toLocaleDateString()),
          datasets: [
            {
              label: 'Kilometers',
              data: checkedPlateData.map(data => data.numKmiPcsRvs),
              borderColor: 'rgba(7,137,126)',
              backgroundColor: 'transparent',
              fill: false,
              tension: 0.4, // Increase tension for more rounded lines
            }
          ]
        };

        // Minimalist chart options
        const chartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          },
          scales: {
            x: {
              ticks: {
                color: 'grey' // X-axis labels in grey
              },
              grid: {
                display: false // Remove x-axis grid lines
              }
            },
            y: {
              ticks: {
                color: 'grey' // Y-axis labels in grey
              },
              grid: {
                display: false // Remove y-axis grid lines
              }
            }
          }
        };

        return (
          <div
            key={index}
            className="relative max-w-md p-6 mx-auto mt-16 bg-white rounded-xl"
            style={{
              boxShadow: "0 10px 15px -3px rgba(223, 241, 240, 0.7), 0 4px 6px -2px rgba(223, 241, 240, 0.05)"
            }}
          >
            {/* Km scalati on the top left */}
            {lastManipulated && (
              <div className="absolute top-0 left-0 px-2 py-1 -mt-3 -ml-3 text-xs font-bold text-white bg-red-500 rounded-full">
                Km scalati: {report.milage_difference}
              </div>
            )}

            {/* Plate details on the top right */}
            <div className="absolute top-0 right-0 flex items-center -mt-3 -mr-3 rounded-full">
              <span className="px-2 py-1 text-xs font-bold text-white bg-blue-700 rounded-l-full">
                I
              </span>
              <span className="px-2 py-1 text-xs font-bold text-blue-700 bg-white">
                {carDetails?.plate}
              </span>
              <span className="px-2 py-1 text-xs font-bold text-white bg-blue-700 rounded-r-full">
                {carDetails?.region}
              </span>
            </div>

            {/* Logo and comment in one line */}
            <div className="flex items-center mb-6">
              <img src={carDetails.car_logo} alt="Car logo" className="w-24 h-24 border-4 border-white " />
              <div className="ml-4">
                {/* Car brand on top of the message */}
                <div className="text-lg font-bold text-gray-700">{carDetails.versions[0].brand_name} {carDetails.versions[0].model_name}</div>
                {/* Comment in the center */}
                <div className="text-gray-700" dangerouslySetInnerHTML={{ __html: report.comment }} />
              </div>
            </div>

            {/* Minimalist Chart at the bottom */}
            <div className="h-24">
              <Line data={chartData} options={chartOptions} />
            </div>
          </div>
        );
      })}
    </div>
  </div>
</div>
<ContentSection></ContentSection>
{/* <SwiperLandingPageOne></SwiperLandingPageOne>

     <Features id="come-funziona"></Features>
       */}
      <section className="py-20 bg-light" id="faq">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="text-center">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                Domande sulle Revisioni Auto?
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
                Tutto il neccesario da sapere
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 mt-16">
            <div className="mx-auto w-full max-w-[650px]">
              <Accordion className="faq space-y-2.5">
                {faq.map((x) => {
                  return (
                    <AccordionItem
                      key={x.id}
                      className="mt-0 bg-white border-none ac"
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton
                          type="button"
                          className="ac-trigger !p-5 relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden"
                        >
                          <span className="leading-[22px]">{x.question}</span>
                          <div className="leading-[22px]">
                            <i className="mdi mdi-plus plus text-xl font-bold leading-[0px]"></i>
                            <i className="mdi mdi-minus minus text-xl font-bold leading-[0px]"></i>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="ac-panel">
                        <p className="ac-text !p-[26px] !pt-[6px] !font-nunito !text-[15px] !font-normal !leading-[22px] !text-muted">
                          {x.answer}
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <FooterOne></FooterOne>
    </>
  );
};

export default HomePage;
