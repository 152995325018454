import { FaCar, FaClock, FaCheck } from 'react-icons/fa';

const feature = [
    {
        id: 1,
        icon: <FaCar />,
        description: "Controllo Km",
    },
    {
        id: 2,
        icon: <FaClock />,
        description: "Ultima Revisone",
    },
    {
        id: 3,
        icon: <FaCheck />,
        description: "Segnala Km",
    }
]

export default feature;
