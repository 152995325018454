import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import MainHeroSection from "../features/common/MainHeroSection";
import PriceGraph from "./GraphListino";
import FooterOne from "../components/footers/FooterOne";
import {
  Card,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Alert,
  IconButton,
  PencilIcon,
  Tooltip,
  Input,
} from "@material-tailwind/react";
import {
  PresentationChartBarIcon,
  ShoppingBagIcon,
  Cog6ToothIcon,
  InboxIcon,
  PowerIcon,
} from "@heroicons/react/24/solid";
import {
  ChevronRightIcon,
  ChevronDownIcon,
  CubeTransparentIcon,
  MagnifyingGlassIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import { CiMapPin } from "react-icons/ci";
import { CiCalendar } from "react-icons/ci";
import { HiAdjustments, HiClipboardList, HiUserCircle } from "react-icons/hi";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { MdDashboard } from "react-icons/md";
import {
  FaCalendar,
  FaGasPump,
  FaPrint,
  FaSave,
  FaCar,
  FaTrash,
  FaLocationArrow,
  FaLeaf,
  FaUser,
} from "react-icons/fa"; // Import the icons
import { CardBody, CardHeader, Typography } from "@material-tailwind/react";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import Chart from "react-apexcharts";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { ButtonGroup, Button } from "@material-tailwind/react";
//import dynamic from "next/dynamic";
//const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });
const Profile = ({ userData, carData }) => {
  const toggleSidebar = () => {
    setOpen(!open);
  };

  const redirectToUserFunction = () => {};

  const [openAlert, setOpenAlert] = React.useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  // State to manage whether the sidebar is open or not
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const TABLE_HEAD = ["Data", "Esito", "Km"];

  const TABLE_ROWS = [
    {
      dataRevisione: "06/05/2021",
      esito: "REGOLARE",
      kmRilevati: "265588",
    },
    {
      dataRevisione: "03/05/2019",
      esito: "REGOLARE",
      kmRilevati: "230828",
    },
    {
      dataRevisione: "03/09/2017",
      esito: "REGOLARE",
      kmRilevati: "230828",
    },
  ];


  const CarDetails = ({ car }) => (
    <div className="grid grid-cols-1 gap-4 p-4 bg-white rounded shadow-md md:grid-cols-3">
      {/* Group 1 */}
      <div>
        <p><strong>Make:</strong> {car.make}</p>
        <p><strong>Model:</strong> {car.model}</p>
        <p><strong>CCM:</strong> {car.ccm}</p>
        <p><strong>Body Type:</strong> {car.body_type}</p>
      </div>
  
      {/* Group 2 */}
      <div>
        <p><strong>Cylinders:</strong> {car.cylinders}</p>
        <p><strong>Seats:</strong> {car.seats}</p>
        <p><strong>Doors:</strong> {car.doors}</p>
        <p><strong>Weight:</strong> {car.weight}</p>
      </div>
  
      {/* Group 3 */}
      <div>
        <p><strong>Fuel Type:</strong> {car.fuel_type}</p>
        <p><strong>Gear Type:</strong> {car.gear_type}</p>
        <p><strong>Power KW:</strong> {car.power_kw}</p>
        <p><strong>VIN Number:</strong> {car.vin_number}</p>
      </div>
    </div>
  );

  // Function to handle opening/closing the sidebar
  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Event listener to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // State for managing the accordion open state
  const [open, setOpen] = useState(false);

  // Function to toggle the open state
  const handleOpen = () => {
    setOpen(!open);
  };

  const checkKM = true;
  // Define event handlers
  const handleDelete = () => {
    // Implement your delete logic here
    console.log("Delete button clicked");
    // Add code to perform delete operation
  };
  const handlePrint = () => {
    // Implement your print logic here
    console.log("Print button clicked");
    // Add code to trigger printing
  };
  const handleSave = () => {
    // Implement your save logic here
    console.log("Save button clicked");
    // Add code to save data
  };
  const [recentSearches, setRecentSearches] = useState([]);

  useEffect(() => {
    // Assuming you make an API call here and set the recent searches data.
    // Replace this with your actual API call.
    const fetchData = async () => {
      try {
        // Make an API call to get recent searches data.
        // const response = await fetch('your_api_endpoint');
        // const data = await response.json();

        // Simulated data for demonstration
        const data = [
          "Ab 123 CD",
          "Ab 123 CD",
          "Ab 123 CD",
          "Ab 123 CD",
          "Ab 123 CD",
        ];

        setRecentSearches(data);
      } catch (error) {
        console.error("Error fetching recent searches:", error);
      }
    };

    fetchData();
  }, []);
  const isInsured = true;

  const cars = [
    {
      make: "Ford",
      model: "Ka/Ka+",
      ccm: "1242",
      body_type: "Berlina",
      // ... other details
    },
    // ... other cars
  ];
  const userAccountType = "FREE";
  const [priceRange, setPriceRange] = useState({
    min: 10000,
    med: 15000,
    max: 20000,
  });
  const listino = 39000; // Replace with your actual listino value
  const medPrice = priceRange.med; // Get the medium price from your state
  const chartConfig = {
    type: "line",
    height: 240,
    series: [
      {
        name: "Price Range",
        data: [priceRange.min, priceRange.med, priceRange.max],
      },
      {
        name: "Listino",
        data: [listino, listino, listino], // constant value across the chart
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#28B463", "#3498DB"], // Different colors for each series
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: ["Min", "Med", "Max"],
      },
      yaxis: {
        labels: {
          formatter: (value) => `€${value}`,
        },
      },
      annotations: {
        yaxis: [
          {
            y: listino,
            borderColor: "#FF5733",
            label: {
              borderColor: "#FF5733",
              style: {
                color: "#fff",
                background: "#FF5733",
              },
              text: "Listino",
            },
          },
        ],
      },
      tooltip: {
        theme: "dark",
      },
    },
  };
  function calculatePercentageDecrease(listino, medPrice) {
    const decreaseAmount = listino - medPrice;
    const percentageDecrease = (decreaseAmount / listino) * 100;
    return percentageDecrease;
  }
  const percentageDecrease = calculatePercentageDecrease(listino, medPrice);
  console.log(
    `The price has decreased by ${percentageDecrease.toFixed(
      2
    )}% from the listino.`
  );
  const dataChart = [
    {
      label: "Graph",
      value: "graph",
      icon: Square3Stack3DIcon,
      desc: "Questo veicolo percorrendo una media di 30 km all'anno ha avuto una decrescenza di prezzo.",
      // Use a function that returns the JSX content for the graph
      content: (chartConfig) => (
        <Card>
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="flex flex-col gap-4 rounded-none md:flex-row md:items-center"
          >
            <div className="p-5 text-white bg-gray-900 rounded-lg w-max">
              <Square3Stack3DIcon className="w-6 h-6" />
            </div>
            <div>
              <Typography variant="h6" color="blue-gray">
                Line Chart
              </Typography>
              <Typography
                variant="small"
                color="gray"
                className="max-w-sm font-normal"
              >
                Questo veicolo percorrendo una media di 30 km all'anno ha avuto
                una decrescenza di prezzo pari al{" "}
                {/* Use your percentageDecrease variable here */}
                {percentageDecrease.toFixed(2)}% all'anno.
              </Typography>
            </div>
          </CardHeader>
          <CardBody className="px-2 pb-0">
            <Chart
              options={chartConfig.options}
              series={chartConfig.series}
              type="line"
              height={240}
            />
          </CardBody>
        </Card>
      ),
    },
  ];
  const data = [
    {
      label: "Dettagli tecnici",
      value: "detail",
      icon: UserCircleIcon,
    },
    {
      label: "Revisione e Bollo",
      value: "insurance",
      icon: Cog6ToothIcon,
      desc: `We're not always in the position that we want to be at.
We're constantly growing. We're constantly making mistakes. We're
constantly trying to express ourselves and actualize our dreams.`,
    },
    {
      label: "Revisioni e Sicurezza",
      value: "inspection",
      icon: Cog6ToothIcon,
    },
  ];
  const [selectedTab, setSelectedTab] = useState(data[0].value);
  console.log("Selected Tab:", selectedTab);
console.log("Data array values:", data.map(item => item.value));
  const handleTabClick = (value) => {
    console.log("Tab clicked:", value);
    setSelectedTab(value);
  };




  const [mileage, setMileage] = useState(0);
  const [isProfileVisible, setIsProfileVisible] = useState(true);
  const toggleProfileVisibility = () => {
    setIsProfileVisible(!isProfileVisible);
  };
  const handleApiCall = async () => {
    try {
      // Make an API call to fetch mileage data based on your requirements
      const response = await fetch("your_api_endpoint");
      const data = await response.json();
      // Update the mileage state with the received data
      setMileage(data.mileage);
      // Calculate the new price range based on mileage
      // You can customize this calculation according to your requirements
      let newMinPrice = 10000;
      let newMaxPrice = 20000;
      if (data.mileage > 50000) {
        newMinPrice = 15000;
        newMaxPrice = 25000;
      }
      // Update the price range state with the new values
      setPriceRange({
        min: newMinPrice,
        max: newMaxPrice,
      });
    } catch (error) {
      console.error("Error fetching mileage data:", error);
    }
  };
  // userData would contain information like the username and recent searches
  // carData would contain information like the make, model, and market price

  return (
    <>
      <div className="flex w-full p-[0%] inset-0 bg-cover bg-center bg-no-repeat h-full bg-[url(../images/bg.png)]">
        <div className="container relative mt-[5%]">
          <div
            className={`grid ${
              open ? "grid-cols-[1fr_4fr]" : "grid-cols-[0.2fr_2.8fr]"
            } items-stretch gap-[1px]`}
          >
            {/* User Profile Section */}
            <div
              className={`flex flex-col ${
                open ? "w-50" : "w-16"
              } h-full overflow-hidden text-gray-900 bg-gray-white rounded`}
              style={{ transition: "width 0.3s" }}
            >
              {/* sidebar content */}

              <div className="h-full rounded-lg shadow ">
                <div
                  className={`sidebar-container ${
                    open ? "sidebar-expanded" : "sidebar-collapsed"
                  }`}
                >
                  <div class="flex flex-col w-full h-full overflow-hidden text-gray-900 bg-white rounded">
                    {open ? (
                      // Full content for expanded state
                    
                      <a className="flex items-center justify-between w-full px-3 mt-3 ml-1">
                        <svg
                          class="w-8 ml-1    h-8 fill-current"
                          preserveAspectRatio="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-width="1.5"
                            d="M18.25 11V7.75L12 4.5 5.75 7.75V11c0 6 6.25 8.75 6.25 8.75s6.25-2.75 6.25-8.75Z"
                          ></path>
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-width="1.5"
                            d="M9 12l2 2 4-4"
                          ></path>
                        </svg>

                        <Typography variant="h6">TargaCheck</Typography>
                        <button
                      className="flex items-center justify-center w-10 h-10 text-white bg-blue-500 rounded-full hover:bg-blue-600"
                      onClick={() => setOpen(!open)}
                    >
                      {open ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16m-7 6h7"
                          />
                        </svg>
                      )}
                    </button>
                      </a>
                      
                    ) : (
                      // Minimal content for collapsed state
                      <a className="flex items-center w-full px-3 mt-3">
                        <button
                      className="flex items-center justify-center w-10 h-10 text-white bg-blue-500 rounded-full hover:bg-blue-600"
                      onClick={() => setOpen(!open)}
                    >
                      {open ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16m-7 6h7"
                          />
                        </svg>
                      )}
                    </button>
                      </a>
                    )}
                    
                   
                    <div className="flex flex-col w-full h-full mt-3 border-t border-gray-300">
                      {/* Content based on 'open' state */}
                      {open ? (
                        <>
                          {/* Expanded content */}
                          <a
                            className="flex items-center w-full h-12 px-3 mt-3 rounded hover:bg-gray-300"
                            href="#"
                          >
                            {/* <svg className="w-6 h-6 ml-2 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg> */}
                          <FaUser></FaUser>
                          <Typography variant="h7">TargaCheck</Typography>
                          </a>
                     

                        
                        </>
                      ) : (
                        <>
                          {/* Collapsed content */}
                          <a
                            className="flex items-center w-full h-12 px-3 mt-3 rounded hover:bg-gray-300"
                            href="#" onClick={() => setOpen(!open)}
                          >
                            <svg
                              className="w-6 h-6 ml-2 stroke-current"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                              />
                            </svg>
                          </a>
                        </>
                      )}
                    </div>

                    <div className="flex flex-col w-full h-full mt-3 border-t border-gray-300">
                      {/* Content based on 'open' state */}
                      {open ? (
                        <>
                          {/* Expanded content */}
                          <a
                            className="flex items-center w-full h-12 px-3 mt-3 rounded hover:bg-gray-300"
                            href="#"
                          >
                            {/* <svg className="w-6 h-6 ml-2 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg> */}
                            <Input
                              icon={<MagnifyingGlassIcon className="w-5 h-5" />}
                              label="Search"
                            />
                          </a>
                          <div className="mt-2">
                            <div className="p-2"></div>
                            {recentSearches.map((search, index) => (
                              <div
                                key={index}
                                className="px-3 py-2 text-sm hover:bg-gray-300"
                              >
                                {search}
                              </div>
                            ))}
                          </div>

                          <Alert
                            open={openAlert}
                            className="mt-auto"
                            onClose={() => setOpenAlert(false)}
                          >
                            <CubeTransparentIcon className="w-12 h-12 mb-4" />
                            <Typography variant="h6" className="mb-1">
                              Upgrade to PRO
                            </Typography>
                            <Typography
                              variant="small"
                              className="font-normal opacity-80"
                            >
                              Upgrade to Material Tailwind PRO and get even more
                              components, plugins, advanced features and
                              premium.
                            </Typography>
                            <div className="flex gap-3 mt-4">
                              <Typography
                                as="a"
                                href="#"
                                variant="small"
                                className="font-medium opacity-80"
                                onClick={() => setOpenAlert(false)}
                              >
                                Dismiss
                              </Typography>
                              <Typography
                                as="a"
                                href="#"
                                variant="small"
                                className="font-medium"
                              >
                                Upgrade Now
                              </Typography>
                            </div>
                          </Alert>
                        </>
                      ) : (
                        <>
                          {/* Collapsed content */}
                          <a
                            className="flex items-center w-full h-12 px-3 mt-3 rounded hover:bg-gray-300"
                            href="#" onClick={() => setOpen(!open)}
                          >
                            <FaUser></FaUser>
                            
                          </a>
                        </>
                      )}
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            {/* Car Details Section */}
            <div className="w-full p-10 ml-4 bg-white rounded-lg shadow">
              <div className="flex flex-col">
                <nav className="flex justify-between" aria-label="Breadcrumb">
                  <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                      <a
                        href="#"
                        className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                      >
                        <svg
                          className="w-3 h-3 me-2.5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                        </svg>
                        Home
                      </a>
                    </li>
                    <li aria-current="page">
                      <div className="flex items-center">
                        <svg
                          className="w-3 h-3 mx-1 text-gray-400 rtl:rotate-180"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <span className="text-sm font-medium text-gray-500 ms-1 md:ms-2 dark:text-gray-400">
                          EB763PT
                        </span>
                      </div>
                    </li>
                  </ol>
                  <div className="inline-flex space-x-2">
                    {/* Add inline-flex here */}
                    <ButtonGroup size="sm">
                      <Button>One</Button>
                      <Button>Two</Button>
                      <Button>Three</Button>
                    </ButtonGroup>
                  </div>
                </nav>
                <div className="h-px my-4 bg-gray-200" />

                <div className="flex w-full p-10 bg-white rounded-lg ">
                  <div className="w-full p-5 bg-white rounded-lg ">
                    <div className="grid grid-cols-2 gap-4 mt-5">
                      <div className="flex items-center space-x-4 p-3.5 rounded-full">
                        <span className="flex items-center justify-center w-10 h-10 text-gray-400 bg-white rounded-full shrink-0">
                          <img
                            src="https://i.postimg.cc/zqPs4t7w/fiat.png"
                            alt="Car Logo"
                            className="w-10 h-10"
                          />
                        </span>
                        <div className="flex flex-col flex-1">
                          <div className="mt-auto divide-x divide-gray-200">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="font-semibold"
                            >
                              Fiat Tipo
                            </Typography>
                            {/* Version */}
                            <Typography variant="h8" color="blue-gray">
                              1.4 DCI StationWagon
                            </Typography>
                          </div>
                        </div>
                      </div>

                      <div class="flex items-center space-x-4 p-3.5 rounded-full">
                        <span class="flex items-center justify-center w-10 h-10 shrink-0 rounded-full bg-white  text-gray-400">
                          <FaCalendar></FaCalendar>
                        </span>
                        <div class="flex flex-col flex-1">
                          <h3 class="text-sm font-medium">Anno</h3>
                          <div class="divide-x divide-gray-200 mt-auto">
                            <span class="inline-block px-3 text-xl leading-none text-gray-900  first:pl-0 font-bold">
                              02/2018
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="flex items-center space-x-4 p-3.5 rounded-full">
                        <span class="flex items-center justify-center w-10 h-10 shrink-0 rounded-full bg-white  text-gray-400">
                          <FaGasPump></FaGasPump>
                        </span>
                        <div class="flex flex-col flex-1">
                          <h3 class="text-sm font-medium">Alimentazione</h3>
                          <div class="divide-x divide-gray-200 mt-auto">
                            <span class="inline-block px-3 text-xl leading-none text-gray-900  first:pl-0 font-bold">
                              Diesel
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="flex items-center space-x-4 p-3.5 rounded-full">
                        <span class="flex items-center justify-center w-10 h-10 shrink-0 rounded-full bg-white  text-gray-400">
                          <FaCar></FaCar>
                        </span>
                        <div class="flex flex-col flex-1">
                          <h3 class="text-sm font-medium">Cilindrata</h3>
                          <div class="divide-x divide-gray-200 mt-auto">
                            <span class="inline-block px-3 text-xl leading-none text-gray-900  first:pl-0 font-bold">
                              2000 / 65 kw
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="flex items-center space-x-4 p-3.5 rounded-full">
                        <span class="flex items-center justify-center w-10 h-10 shrink-0 rounded-full bg-white  text-gray-400">
                          <FaLocationArrow></FaLocationArrow>
                        </span>
                        <div class="flex flex-col flex-1">
                          <h3 class="text-sm font-medium">Luogo</h3>
                          <div class="divide-x divide-gray-200 mt-auto">
                            <span class="inline-block px-3 text-xl leading-none text-gray-900  first:pl-0 font-bold">
                              Sondrio(SO)
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="flex items-center space-x-4 p-3.5 rounded-full">
                        <span class="flex items-center justify-center w-10 h-10 shrink-0 rounded-full bg-white  text-gray-400">
                          <FaLeaf></FaLeaf>
                        </span>
                        <div class="flex flex-col flex-1">
                          <h3 class="text-sm font-medium">Classe Ambientale</h3>
                          <div class="divide-x divide-gray-200 mt-auto">
                            <span class="inline-block px-3 text-xl leading-none text-gray-900  first:pl-0 font-bold">
                              Euro 5B
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center space-x-4 p-3.5 rounded-full">
                        <span className="relative flex items-center justify-center w-10 h-10 text-gray-400 bg-white rounded-full shrink-0">
                          <span className="relative flex w-5 h-5">
                            <span
                              className={`animate-ping absolute inline-flex h-full w-full rounded-full ${
                                isInsured ? "bg-green-400" : "bg-red-400"
                              } opacity-75`}
                            ></span>
                            <span
                              className={`relative inline-flex rounded-full h-5 w-5 ${
                                isInsured ? "bg-green-500" : "bg-red-500"
                              }`}
                            ></span>
                          </span>
                        </span>
                        <div className="flex flex-col flex-1">
                          <Typography variant="small" className="font-medium">
                            Assicurazione
                          </Typography>
                          <Typography variant="small" color="gray">
                            Il veicolo è assicurato
                          </Typography>
                        </div>
                      </div>

                      <div className="flex items-center space-x-4 p-3.5 rounded-full">
                        <span className="relative flex items-center justify-center w-10 h-10 text-gray-400 bg-white rounded-full shrink-0">
                          <span className="relative flex w-5 h-5">
                            <span
                              className={`animate-ping absolute inline-flex h-full w-full rounded-full ${
                                checkKM ? "bg-green-400" : "bg-red-400"
                              } opacity-75`}
                            ></span>
                            <span
                              className={`relative inline-flex rounded-full h-5 w-5 ${
                                checkKM ? "bg-green-500" : "bg-red-500"
                              }`}
                            ></span>
                          </span>
                        </span>
                        <div className="flex flex-col flex-1">
                          <Typography variant="small" className="font-medium">
                            Neopatentato
                          </Typography>
                          <Typography variant="small" color="gray">
                            Veicolo guidabile da Neopatentato
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex">
                    <Card className="w-full h-full">
                      <CardHeader
                        floated={false}
                        shadow={false}
                        className="rounded-none"
                      >
                        <div className="gap-8 mb-4 md:flex-row md:items-center">
                          <div>
                            <Typography variant="h5" color="blue-gray">
                              Revisioni
                            </Typography>
                            <Typography
                              color="gray"
                              className="mt-1 font-normal"
                            >
                              Lista di tutte le revisioni
                            </Typography>
                          </div>
                          <div className="flex w-full gap-2 shrink-0 md:w-max">
                            <div className="w-full md:w-72"></div>
                          </div>
                        </div>
                      </CardHeader>
                      <CardBody className="px-0 overflow-hidden">
                        <table className="w-full text-left table-auto min-w-max">
                          <thead>
                            <tr>
                              {TABLE_HEAD.map((head) => (
                                <th
                                  key={head}
                                  className="p-4 border-y border-blue-gray-100 bg-blue-gray-50/50"
                                >
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                  >
                                    {head}
                                  </Typography>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {TABLE_ROWS.map(
                              ({ dataRevisione, esito, kmRilevati }, index) => {
                                const isLast = index === TABLE_ROWS.length - 1;
                                const rowClasses = isLast
                                  ? "p-4"
                                  : "p-4 border-b border-blue-gray-50";
                                const esitoClasses =
                                  esito === "REGOLARE"
                                    ? "text-green-500"
                                    : "text-red-500";

                                return (
                                  <tr key={dataRevisione}>
                                    <td className={rowClasses}>
                                      <div className="flex items-center gap-3">
                                        <Typography
                                          variant="small"
                                          color="blue-gray"
                                          className="font-bold"
                                        >
                                          {dataRevisione}
                                        </Typography>
                                      </div>
                                    </td>
                                    <td
                                      className={`${rowClasses} ${esitoClasses}`}
                                    >
                                      <Typography
                                        variant="small"
                                        className="font-normal"
                                      >
                                        {esito}
                                      </Typography>
                                    </td>
                                    <td className={rowClasses}>
                                      <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                      >
                                        {kmRilevati}
                                      </Typography>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>

                        <div className="flex items-center space-x-4 p-3.5 rounded-full">
                        <span className="relative flex items-center justify-center w-10 h-10 text-gray-400 bg-white rounded-full shrink-0">
                          <span className="relative flex w-5 h-5">
                            <span
                              className={`animate-ping absolute inline-flex h-full w-full rounded-full ${
                                checkKM ? "bg-green-400" : "bg-red-400"
                              } opacity-75`}
                            ></span>
                            <span
                              className={`relative inline-flex rounded-full h-5 w-5 ${
                                checkKM ? "bg-green-500" : "bg-red-500"
                              }`}
                            ></span>
                          </span>
                        </span>
                        <div className="flex flex-col flex-1">
                          <Typography variant="small" className="font-medium">
                            Verifica Km
                          </Typography>
                          <Typography variant="small" color="gray">
                            Ultima revisione: km ok
                          </Typography>
                        </div>
                      
                      
                      
                      
                      </div>


                      
                      </CardBody>
                    </Card>
                  </div>
                </div>
            <div className="p-10">
                <Tabs value={selectedTab}>
              <TabsHeader>
                {data.map(({ label, value, icon }) => (
                  <Tab key={value} value={value} onClick={() => handleTabClick(value)}>
                    <div className="flex items-center gap-2">
                      {React.createElement(icon, { className: "w-5 h-5" })}
                      {label}
                    </div>
                  </Tab>
                ))}
              </TabsHeader>
            </Tabs>

            {selectedTab === 'detail' && (
              <div>
                {cars.map((car, index) => (
                  <CarDetails key={index} car={car} />
                ))}
              </div>
            )}

            
              {selectedTab === 'insurance' && (
                            <div>
                              {cars.map((car, index) => (
                                <CarDetails key={index} car={car} />
                              ))}
                            </div>
                          )}

            {selectedTab === 'inspection' && (
                <div>
                    {cars.map((car, index) => (
                      <CarDetails key={index} car={car} />
                       ))}
                </div>
                )}


                <div className="justify-center flex-1">
                  <div className="grid grid-cols-1 gap-2 p-4">
                    {/* Car image */}
                    <div className="text-left">
                      {/*<img src="https://nextrent.it/storage/6pmLc9n5S0vfrIwY9yQcKMRuGhNl7HtCiT2cuvMG.png" alt="Car" className="items-center object-cover mb-4 text-center rounded-lg h-50" />*/}
                    </div>
                    <div className="text-right">
                      <div className="flex items-center justify-between">
                        <Typography variant="h5">Prezzo Range</Typography>
                        <div className="flex-grow h-px mb-3 bg-gradient-to-r from-transparent to-gray-400" />
                      </div>
                      <div className="flex-1">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            {/* Market price details */}
                          </div>
                          <button
                            onClick={handleApiCall}
                            className="px-4 py-2 text-right text-white bg-blue-600 rounded-lg hover:btext-right"
                          >
                            Fetch Mileage
                          </button>
                        </div>
                        {/* Price ranges and listino */}
                        <div className="grid grid-cols-3 gap-4 p-4">
                          {/* Minimum Price */}
                          <div className="text-center">
                            <div className="mb-1 text-xs text-gray-600">
                              From €10,000
                            </div>
                            <div className="relative p-2 text-center text-white transition duration-500 ease-in-out transform rounded-l-lg shadow-lg cursor-pointer bg-gradient-to-r from-green-400 to-green-500 hover:from-green-500 hover:to-green-600 hover:scale-105">
                              <div className="absolute items-center justify-center hidden px-2 py-1 text-xs text-green-800 transform -translate-x-1/2 bg-white rounded shadow-sm group-hover:flex -top-8 left-1/2">
                                Min Price
                              </div>
                              €{priceRange.min}
                            </div>
                          </div>
                          {/* Average Price */}
                          <div className="text-center">
                            <div className="mb-1 text-xs text-gray-600">
                              To €19,999
                            </div>
                            <div className="relative p-2 text-center text-white transition duration-500 ease-in-out transform shadow-lg cursor-pointer bg-gradient-to-r from-blue-400 to-blue-500 hover:from-blue-500 hover:to-blue-600 hover:scale-105">
                              <div className="absolute items-center justify-center hidden px-2 py-1 text-xs text-blue-800 transform -translate-x-1/2 bg-white rounded shadow-sm group-hover:flex -top-8 left-1/2">
                                Average Price
                              </div>
                              €{priceRange.med}
                            </div>
                          </div>
                          {/* Maximum Price */}
                          <div className="text-center">
                            <div className="mb-1 text-xs text-gray-600">
                              Above €20,000
                            </div>
                            <div className="relative p-2 text-center text-white transition duration-500 ease-in-out transform rounded-r-lg shadow-lg cursor-pointer bg-gradient-to-r from-red-400 to-red-500 hover:from-red-500 hover:to-red-600 hover:scale-105">
                              <div className="absolute items-center justify-center hidden px-2 py-1 text-xs text-red-800 transform -translate-x-1/2 bg-white rounded shadow-sm group-hover:flex -top-8 left-1/2">
                                Max Price
                              </div>
                              €{priceRange.max}
                            </div>
                          </div>
                        </div>

                        {/* Display mileage */}
                        {/* Graph (Placeholder for actual graph component) */}
                        <div className="flex items-center justify-between">
                          <Typography variant="h5" className="mt-5 mb-5">
                            Grafici
                          </Typography>
                          <div className="flex-grow h-px mb-3 bg-gradient-to-r from-transparent to-gray-400" />
                        </div>
                        <Tabs>
                          <TabsHeader>
                            {dataChart.map(({ label, value, icon }) => (
                              <Tab
                                key={value}
                                value={value}
                                onClick={() => handleTabClick(value)}
                              >
                                <div className="flex items-center gap-2">
                                  {React.createElement(icon, {
                                    className: "w-5 h-5",
                                  })}
                                  {label}
                                </div>
                              </Tab>
                            ))}
                          </TabsHeader>
                          <div>
                            {dataChart
                              .find((tab) => tab.value === selectedTab)
                              ?.content(chartConfig)}
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="grid-container"></div>

      <FooterOne></FooterOne>
    </>
  );
};
export default Profile;
