import React from 'react';

const faq = [
    {
        id: 1,
        question: "Quando si deve fare la revisione dell'auto?",
        answer: "La revisione dell'auto deve essere fatta ogni due anni dalla data della prima immatricolazione del veicolo."
    },
    {
        id: 2,
        question: "Quanto tempo ho per la revisione dopo la scadenza?",
        answer: "Dopo la scadenza, hai 20 giorni di tempo per effettuare la revisione dell'auto senza ricevere sanzioni."
    },
    {
        id: 3,
        question: "Che succede se si fa la revisione in ritardo?",
        answer: "Se fai la revisione dell'auto in ritardo, potresti ricevere una multa. È importante rispettare i tempi stabiliti per evitare sanzioni."
    },
    {
        id: 4,
        question: "Cosa succede se faccio la revisione il giorno dopo la scadenza?",
        answer: "Se fai la revisione dell'auto il giorno dopo la scadenza, potresti ricevere una sanzione. È meglio pianificare la revisione in anticipo per evitare problemi."
    },
    {
        id: 5,
        question: "Quali documenti devo portare con me per la revisione dell'auto?",
        answer: "Per la revisione dell'auto, assicurati di portare con te il libretto di circolazione, il certificato di assicurazione e il documento di identità."
    },
    {
        id: 6,
        question: "Cosa devo fare se il mio veicolo non supera la revisione?",
        answer: "Se il tuo veicolo non supera la revisione, dovrai effettuare le riparazioni necessarie per conformarti agli standard di sicurezza e ambientali. Successivamente, potrai ripresentare il veicolo per la revisione."
    },
    {
        id: 7,
        question: "Posso effettuare la revisione dell'auto in un'altra regione?",
        answer: "Sì, puoi effettuare la revisione dell'auto in un'altra regione, ma dovrai assicurarti di rispettare le norme e i requisiti della regione in cui stai effettuando la revisione."
    },
    {
        id: 8,
        question: "Quanto dura una revisione dell'auto?",
        answer: "La durata di una revisione dell'auto può variare, ma in genere richiede circa 30-45 minuti. Tuttavia, potrebbe essere necessario più tempo se sono necessarie riparazioni o ulteriori controlli."
    }
    
];

export default faq;





