import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../../src/assets/images/logo.png";

const Header = () => {
  const location = useLocation();

  const menuActive = () => {
    const menu = document.querySelector(".mobile-menu");
    menu.classList.toggle("hidden");
  };

  return (
    <div>
      <header>
        <nav className="fixed w-full navbar navbar-expand-lg navbar-light horizontal-nav">
          <div className="container">
            <div className="flex items-center justify-between">
              <NavLink className="navbar-brand" to="/">
                <img src={logo} className="mr-2 w-[200px]" alt="logo" />
              </NavLink>
              <button
                onClick={() => menuActive()}
                className="hidden navbar-toggler text-end mobile-menu-button"
              >
                <span className="navbar-toggler-icon">
                  <i className="ti-menu icon-align-right"></i>
                </span>
              </button>

              <div
                className="navbar-collapse flex-1 mobile-menu hidden min-[991px]:block"
                id="navbarNav"
              >
                <ul className="justify-end navbar-nav horizontal-menu">
                <li className="scroll dropdown group/menu">
                    <a
                      href="#home" // Keeps accessibility and allows for standard behavior with middle-click/new tab
                      onClick={(e) => {
                        // Prevent default anchor link behavior
                        e.preventDefault();
                        // Scroll to the FAQ section
                        const faqSection = document.getElementById('home');
                        if (faqSection) {
                          faqSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                      }}
                    >
                      <span>Home</span>
                    </a>
                  </li>

                  <li className="scroll dropdown group/menu">
                    <a
                      href="#come-funziona" // Keeps accessibility and allows for standard behavior with middle-click/new tab
                      onClick={(e) => {
                        // Prevent default anchor link behavior
                        e.preventDefault();
                        // Scroll to the FAQ section
                        const faqSection = document.getElementById('come-funziona');
                        if (faqSection) {
                          faqSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                      }}
                    >
                      <span>Come Funziona?</span>
                    </a>
                  </li>
                  <li className="scroll dropdown group/menu">
                    <a
                      href="#faq" // Keeps accessibility and allows for standard behavior with middle-click/new tab
                      onClick={(e) => {
                        // Prevent default anchor link behavior
                        e.preventDefault();
                        // Scroll to the FAQ section
                        const faqSection = document.getElementById('faq');
                        if (faqSection) {
                          faqSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                      }}
                    >
                      <span>Faq</span>
                    </a>
                  </li>

                  
               {/*    <li className="scroll dropdown group/menu">
                    <NavLink
                      className={({ isActive }) => {
                        return location.pathname.match("/contatto")
                      }}
                      to="/contatto"
                    >
                      <span>Contatto</span>
                    </NavLink>
                  </li> */}
                
                  
                <a href="https://valutaauto.it" target="_blank" rel="noopener noreferrer">
  <button className="btn btn-sm btn-custom navbar-btn btn-rounded">
    Valuta Auto
  </button>
</a>

                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
