import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/headers/Header";

const MainLayout = () => {
  return (
    <>
      <Header />
      <Outlet /> {/* This will render the appropriate child route */}
    </>
  );
};

export default MainLayout;