import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "../src/assets/css/themify-icons.css";
import "../src/assets/css/materialdesignicons.min.css";
import "../node_modules/swiper/swiper-bundle.min.css";
import "../src/assets/css/tailwind.css";
import "../src/assets/css/style.css";
import { Analytics } from '@vercel/analytics/react';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <div className="text-base antialiased font-nunito">
    <App/>
    <Analytics />
    </div>
);
