import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const PriceGraph = ({ priceRange, listino }) => {
    
  // Data for the graph
  const data = {
    labels: ['Listino', 'Min Price', 'Med Price','Max Price'],
    datasets: [
      {
        label: 'Price',
        data: [listino, priceRange.max,priceRange.med, priceRange.min],
        borderColor: ['green', 'blue', 'red','yellow'], // Colors for the lines
        borderWidth: 2, // Line width
        pointRadius: 4, // Point size
        fill: false, // Disable fill under the line
      },
    ],
  };

  // Options for the graph
  const options = {
    maintainAspectRatio: true, // Allow the graph to resize
    scales: {
      y: {
        beginAtZero: true,
        type: 'linear'
      },
    },
  };

  return (      
        <Line data={data} options={options} />
  );
};

export default PriceGraph;
