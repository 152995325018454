import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y, Pagination } from "swiper";
import swiperdata from "../../assets/fake-data/swiperdata";
import clientSide from "../../assets/images/client_side.png";

const SwiperLandingPageOne = () => {
  const [dataBlock] = useState({
    clientSideImage: clientSide,
    subtitle:
      "Due to its widespread use as filler text for layouts, non-readability is of great importance.",
    title: "What Our Users Have To Say.",
  });

  const [ebookChapters] = useState([
    { title: "Capitolo 1: Introduzione alla Manipolazione del Contachilometri", page: 4 },
    { title: "Capitolo 2: Riconoscere i Segnali di Manipolazione", page: 8 },
    // Aggiungi gli altri capitoli qui...
  ]);

  const [fraudSection] = useState({
    title: "Come Non Essere Fregato",
    subtitle: "Impara a riconoscere i segni di frode e a proteggerti dagli acquisti rischiosi.",
  });

  return (
    <>
      <section className="pt-20 bg-light">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center">
            <div className="order-2 md:order-1">
              <img
                src={dataBlock.clientSideImage}
                alt="client side"
                className="mx-auto"
              />
            </div>
            <div className="relative order-1 client-sec md:order-2">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {fraudSection.title}
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
                {fraudSection.subtitle}
              </p>
              <div className="mt-12 mb-10">
                <p className="text-muted">
                  Scopri il nostro e E-Book sulla prevenzione dello schilometratraggio <span className="text-custom">10+ Capitoli</span>
                </p>
              </div>
              <Swiper
                modules={[Scrollbar, A11y, Pagination]}
                slidesPerView="auto"
                pagination={{ el: ".swiper-pagination" }}
                loop={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  767: {
                    slidesPerView: 1,
                  },
                  991: {
                    slidesPerView: 1,
                  },
                }}
              >
                <div className="swiper-wrapper">
                  {swiperdata.map((item) => (
                    <SwiperSlide key={item.id}>
                      <div className="flex items-start gap-8">
                        <div className="testi-icon testi-left h-[52px] text-center w-[52px] leading-[52px] rounded-full text-white bg-custom inline-block text-[42px] shrink-0">
                          <i className={item.icon}></i>
                        </div>
                        <div className="testi-desc">
                          <h3 className="capitalize text-2xl leading-[1.6] font-semibold">
                            {item.description}
                          </h3>
                          <h5 className="mt-6 text-xl font-bold">{item.user}</h5>
                          <p className="mt-1 text-muted">{item.address}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </div>

                <div className="mt-5 space-x-2 swiper-pagination"></div>
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      {/* Aggiunta dei Capitoli del E-Book */}
      <section className="pt-20 pb-10 bg-light">
        <div className="container">
          <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
            {fraudSection.title}
          </h2>
          <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
            {fraudSection.subtitle}
          </p>
          <div className="mt-12">
            {ebookChapters.map((chapter, index) => (
              <div key={index} className="mb-6">
                <h3 className="text-xl font-semibold">{chapter.title}</h3>
                <p className="text-muted">Pagina: {chapter.page}</p>
              </div>
            ))}
          </div>
          <div className="mt-8">
            <p className="text-lg font-semibold text-center text-custom">Impara a proteggere te stesso e il tuo portafoglio!</p>
            <div className="flex justify-center mt-4">
              <button className="px-6 py-3 text-white transition duration-300 rounded-lg shadow-md bg-custom hover:bg-opacity-90">Acquista Ora</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SwiperLandingPageOne;
