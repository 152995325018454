import React, { useState } from "react";
import aboutImg from "../../assets/images/milage.jpg";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const ContentSection = ({ id, children }) => {
  const [designDataBlock] = useState({
    name: "Come funziona?",
    title: "Scopri tutte le informazioni per un buon acquisto",
    description:
      "Create custom landing pages with Landik that converts more visitors than any website. With lots of unique blocks, you can easily build a page without coding.",
  });

  const [userDataBlock] = useState({
    name: "Dati sulle Auto Schilometrate in Italia",
    title: "Percentuale di Auto Schilometrate:",
    description: "Secondo carVertical, circa il 15% delle auto usate vendute in Italia ha il chilometraggio alterato, posizionando l'Italia tra i paesi europei con il tasso più alto di auto schilometrate.",
    additionalInfo: [
      {
        subtitle: "Numero di Veicoli Coinvolti:",
        text: "Ogni anno, circa 450.000 delle 3 milioni di auto usate vendute in Italia potrebbero avere il contachilometri manomesso."
      },
      {
        subtitle: "Perdita Economica:",
        text: "L'ACI stima una perdita complessiva di circa 2 miliardi di euro all'anno per i consumatori italiani a causa delle auto schilometrate."
      },
      {
        subtitle: "Prezzo Gonfiato:",
        text: "Le auto con chilometraggio ridotto possono essere vendute fino al 25-30% in più rispetto al loro reale valore di mercato."
      },
      {
        subtitle: "Rischi di Sicurezza:",
        text: "Le auto schilometrate presentano maggiori rischi di guasti meccanici, aumentando i rischi di incidenti stradali."
      }
    ]
  });
  
  

  // Features for KM Sicuro
  const kmSicuroFeatures = [
    {
      icon: "mdi-check",
      title: "Verifica Gratuita Del Chilometraggio",
      description: "Verifichiamo gratuitamente il chilometraggio confrontando i dati con la banca dati delle revisioni.",
    },
    
    {
      icon: "mdi-check",
      title: "Segnala Chilometraggio",
      description: "Segnala veicoli con chilometraggio alterato per proteggere altri acquirenti.",
    },
    
    {
      icon: "mdi-check",
      title: "Notifica Scadenza Revisone",
      description: "Ricevi notifiche automatiche per la scadenza della revisione.",
    },
    {
      icon: "mdi-check",
      title: "Garanzia di acquisto sicuro",
      description: "Scopri se il veicolo ha avuto manipolazioni del chilometraggio.",
    },
  ];
  
  return (
    <>
    
      <section className="py-20 bg-white" id="come-funziona">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[30px]">
            <div className="max-w-[450px] mx-auto">
              <p className="[&>span]:bg-custom tracking-[6px] text-xs uppercase [&>span]:h-3 z-10 [&>span]:w-[60px] [&>span]:inline-block [&>span]:absolute [&>span]:opacity-10 relative ">
                <span></span>
                {designDataBlock.name}
              </p>
              <h3 className="font-bold text-[40px] capitalize mt-6">
                {designDataBlock.title}
              </h3>
              <div className="mt-16">
            <ul className="list-none">
              {kmSicuroFeatures.map((feature, index) => (
                <li className="flex items-start gap-5 mt-8" key={index}>
                  <div className="grow-0">
                    <i className={`mdi ${feature.icon} bg-custom text-white text-lg leading-[26px] h-[26px] w-[26px] text-center rounded-full inline-block`}></i>
                  </div>
                  <div>
                    <h3 className="mb-4 text-lg font-bold">{feature.title}</h3>
                    <p className="text-[15px] leading-[22px] text-muted">
                      {feature.description}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
              <div className="mt-10">
              

{/* <Link
  to="scadenza-revisione" // Imposta l'id della sezione target
  smooth={true} // Attiva lo smooth scroll
  duration={500} // Durata dello scroll in millisecondi
  className="rounded-full btn-custom"
>
  Provalo Ora
</Link> */}

              </div>
            </div>
            <div>
              {/* <img src={aboutImg} alt="com" className="mx-auto rounded-[30px]" /> */}
              <section class="py-15 bg-light rounded-xl">
   <div class="containerPlate ">
      <div class="grid grid-cols-1 gap-4 mt-5 md:grid-cols-1">
         <div class="flex items-center p-3.5 space-x-4 rounded-full">
            <div class="grid place-items-center bg-neutral-100">
               <div class="rounded-lg bg-white shadow-lg">
                  <div class="flex w-full rounded-lg border-1 border-green bg-white-500 shadow"><label class="flex flex-col justify-between bg-blue-700 rounded-l-lg p-4 text-1xl font-bold text-white text-center"><img class="h-4" src="https://cdn.cdnlogo.com/logos/e/51/eu.svg"/>I</label><label class="p-4 font-mono text-3xl font-bold text-center">GJ505RR</label><label class="flex flex-col justify-between bg-blue-700 rounded-r-lg p-4 text-1xl font-bold text-white">MI</label></div>
               </div>
            </div>
         </div>
         <div class="flex items-center p-3.5 space-x-4 rounded-full">
            <span class="flex items-center justify-center w-10 h-10 text-gray-400 bg-white rounded-full shrink-0"><img src="https://www.izmostock.com/wp-content/uploads/2015/12/Audi.png" alt="AUDI logo" class="w-10 h-10"/></span>
            <div class="flex-1">
               <h6 class="block antialiased tracking-normal font-sans text-base leading-relaxed text-blue-gray-900 font-semibold">AUDI A4 B8 Avant (8K5)</h6>
               <p class="block antialiased font-sans text-base font-light leading-relaxed text-blue-gray-900">2.0 TDI (177 hp) [2011-2015]</p>
            </div>
         </div>
         <div class="flex items-center p-3.5 space-x-4 rounded-full">
            <span class="flex items-center justify-center w-10 h-10 text-gray-400 bg-white rounded-full shrink-0">
               <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm436-44v-36c0-26.5-21.5-48-48-48h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v36c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12z"></path>
               </svg>
            </span>
            <div class="ml-2 text-sm">
               <p class="block antialiased font-sans text-base leading-relaxed text-blue-gray-900 font-medium">Prossima Revisione</p>
               <p class="block antialiased font-sans text-base font-light leading-relaxed text-gray-700">2025-06-30 (392 giorni rimanenti)</p>
            </div>
         </div>
      </div>
      <div class="flex items-center ">
         <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full h-full">
            <div class="relative bg-clip-border mt-4 mx-4 overflow-hidden bg-white text-gray-700 rounded-none">
               <div class="gap-8 mb-4 md:flex-row md:items-center">
                  <div>
                     <h5 class="block antialiased tracking-normal font-sans text-xl font-semibold leading-snug text-blue-gray-900">Revisioni</h5>
                     <p class="block antialiased font-sans text-base leading-relaxed text-gray-700 mt-1 font-normal">Lista di tutte le revisioni</p>
                  </div>
                  <div class="flex w-full gap-2 shrink-0 md:w-max">
                     <div class="w-full md:w-72"></div>
                  </div>
               </div>
            </div>
            <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full h-full overflow-hidden">
               <table class="w-full text-left table-auto min-w-max">
                  <thead>
                     <tr>
                        <th class="p-4 border-b border-blue-gray-100 bg-blue-gray-50">
                           <p class="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">Date</p>
                        </th>
                        <th class="p-4 border-b border-blue-gray-100 bg-blue-gray-50">
                           <p class="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">Esito</p>
                        </th>
                        <th class="p-4 border-b border-blue-gray-100 bg-blue-gray-50">
                           <p class="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">Mileage</p>
                        </th>
                        <th class="p-4 border-b border-blue-gray-100 bg-blue-gray-50">
                           <p class="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">Notificami</p>
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr class="">
                        <td class="p-4 border-b border-blue-gray-50">
                           <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-medium">2018-08-01</p>
                        </td>
                        <td class="p-4 border-b border-blue-gray-50 bg-blue-gray-50/50">
                           <p class="block antialiased font-sans text-sm leading-normal font-bold text-green-600">Positivo</p>
                        </td>
                        <td class="p-4 border-b border-blue-gray-50">
                           <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">198.333 Km </p>
                        </td>
                        <td class="p-4 border-b border-blue-gray-50">
                           <div>  </div>
                        </td>
                     </tr>
                     <tr class="bg-red-100">
                        <td class="p-4 border-b border-blue-gray-50">
                           <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-medium">2020-07-27</p>
                        </td>
                        <td class="p-4 border-b border-blue-gray-50 bg-blue-gray-50/50">
                           <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">Km Manipolato</p>
                        </td>
                        <td class="p-4 border-b border-blue-gray-50">
                           <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">87.166 Km <span>⚠️</span></p>
                        </td>
                        <td class="p-4 border-b border-blue-gray-50"><button class="px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700">Segnala</button></td>
                     </tr>
                     <tr class="">
                        <td class="p-4 border-b border-blue-gray-50">
                           <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-medium">2023-06-30</p>
                        </td>
                        <td class="p-4 border-b border-blue-gray-50 bg-blue-gray-50/50">
                           <p class="block antialiased font-sans text-sm leading-normal font-bold text-green-600">Positivo</p>
                        </td>
                        <td class="p-4 border-b border-blue-gray-50">
                           <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">124.255 Km </p>
                        </td>
                        <td class="p-4 border-b border-blue-gray-50">
                           <div>  </div>
                        </td>
                     </tr>
                     <tr class="">
                        <td class="p-4 border-b border-blue-gray-50">
                           <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-medium">2025-06-30</p>
                        </td>
                        <td class="p-4 border-b border-blue-gray-50 bg-blue-gray-50/50">
                           <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold filter blur-[2px]">-------</p>
                        </td>
                        <td class="p-4 border-b border-blue-gray-50">
                           <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal filter blur-[2px]">------- </p>
                        </td>
                        <td class="p-4 border-b border-blue-gray-50">
                           <add-to-calendar-button name="Scadenza revisone" description="Oggi scade la revisione della tua auto" startdate="2025-06-30" starttime="10:15" endtime="17:45" timezone="Europe/Berlin" location="Centro" options="'Apple','Google','iCal','Outlook.com','Yahoo','Microsoft365','MicrosoftTeams'" trigger="click" hidetextlabelbutton="true" size="4" atcb-button-id="atcb-btn-2" class="atcb-light" atcb-last-event="initialization:atcb-btn-2"></add-to-calendar-button>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <div class="flex items-center space-x-4 p-3.5 rounded-full">
                  <span class="relative flex items-center justify-center w-10 h-10 text-gray-400 bg-white rounded-full shrink-0"><span class="relative flex w-5 h-5"><span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span><span class="relative inline-flex rounded-full h-5 w-5 bg-red-500"></span></span></span>
                  <div class="flex flex-col flex-1">
                     <p class="block antialiased font-sans text-sm leading-normal text-inherit font-medium">Verifica Km</p>
                     <p class="block antialiased font-sans text-sm font-light leading-normal text-red-500">Oops, il km nel veicolo è stato scalato di 111167 Km</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="flex items-center justify-center p-3.5 space-x-4 rounded-full mt-2">
         <div class="inline-flex rounded-md shadow-sm" role="group">
            <button type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">
               <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z"></path>
               </svg>
               Condividi
            </button>
            <button type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">
               <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M448 192V77.25c0-8.49-3.37-16.62-9.37-22.63L393.37 9.37c-6-6-14.14-9.37-22.63-9.37H96C78.33 0 64 14.33 64 32v160c-35.35 0-64 28.65-64 64v112c0 8.84 7.16 16 16 16h48v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h48c8.84 0 16-7.16 16-16V256c0-35.35-28.65-64-64-64zm-64 256H128v-96h256v96zm0-224H128V64h192v48c0 8.84 7.16 16 16 16h48v96zm48 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"></path>
               </svg>
               Stampa
            </button>
            <button type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">
               <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z"></path>
               </svg>
               Valuta Veicolo
            </button>
         </div>
      </div>
   </div>
</section>

         </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[30px] mt-16">
 
  <div className="max-w-[450px] mx-auto">
    <p className="[&>span]:bg-custom tracking-[6px] text-xs uppercase [&>span]:h-3 z-10 [&>span]:w-[60px] [&>span]:inline-block [&>span]:absolute [&>span]:opacity-10 relative ">
      <span></span>
      {userDataBlock.name}
    </p>
    <h3 className="font-bold text-[40px] capitalize mt-6">
      {userDataBlock.title}
    </h3>
    <p className="mt-6 text-muted">{userDataBlock.description}</p>
    <div className="mt-6">
      {userDataBlock.additionalInfo.map((info, index) => (
        <div key={index} className="mt-4">
          <h4 className="text-lg font-bold">{info.subtitle}</h4>
          <p className="text-muted">{info.text}</p>
        </div>
      ))}
    </div>
   {/*  <div className="mt-10">
      <Link to="#" className="rounded-full btn-custom">
        Explore
      </Link>
    </div> */}
  </div>
  <div>
    <img src={aboutImg} alt="com" className="mx-auto rounded-[30px]" />
   
  </div>
</div>

        </div>
      </section>
    </>
  );
};

export default ContentSection;
