const overviewData = [
    {
        id:1,
        type: "text",
        name: "Your Name",
        placeHolder: "Your Name...",
        class:"form-input bg-[#f8f9fa] border !border-[#edf0f3] focus:!border-custom"
    },
    {
        id:2,
        type: "email",
        name: "Your Email",
        placeHolder: "Your Email...",
        class:"form-input bg-[#f8f9fa] border !border-[#edf0f3] focus:!border-custom"
    },
    {
        id:3,
        type: "text",
        name: "Your Subject",
        placeHolder: "Your Subject...",
        class:"form-input bg-[#f8f9fa] border !border-[#edf0f3] focus:!border-custom"
    },
    {
        id:4,
        type: "textarea",
        name: "Your Message",
        placeHolder: "Your Message...",
        class:"form-input bg-[#f8f9fa] border !border-[#edf0f3] focus:!border-custom h-auto"
    },
]

export default overviewData